<template>
  <div class="table row">
    <div class="col-8 m-auto">
      <BootstrapTable :columns="columns" :data="data" :options="options" />
    </div>
    <div class="col-8 m-auto">
      <b-table
        striped
        hover
        id="my-table"
        :busy.sync="isBusy"
        :itemsX="items"
        :items="myProvider"
        :fields="fields"
      ></b-table>
    </div>
  </div>
</template>

<script>
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm.js';

export default {
  components: {
    BootstrapTable,
  },
  data() {
    return {
      isBusy: false,
      columns: [
        {
          title: 'Item ID',
          field: 'id',
        },
        {
          field: 'client.name',
          title: 'Client',
        },
        {
          field: 'title',
          title: 'Titre',
        },
        {
          field: 'createdDate',
          title: 'Date de création',
        },
      ],
      data: [],
      fields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'client_id',
          sortable: true,
        },
        {
          key: 'client.name',
          sortable: true,
        },
        {
          key: 'title',
          sortable: false,
        },
        {
          key: 'createdDate',
          label: 'Date de création',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          variant: 'danger',
        },
      ],
      items: [],
      options: {
        search: true,
        showColumns: true,
      },
    };
  },
  mounted() {
    this.getItems();
    this.myProvider();
  },
  methods: {
    getItems() {
      this.axios
        .get('http://localhost:3000/issues')
        .then(response => {
          this.data = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    myProvider() {
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      this.isBusy = true;
      const promise = this.axios.get('http://localhost:3000/issues');

      return promise
        .then(response => {
          const items = response.data;
          // Here we could override the busy state, setting isBusy to false
          this.isBusy = false;

          return items;
        })
        .catch(error => {
          // Here we could override the busy state, setting isBusy to false
          this.isBusy = false;
          // Returning an empty array, allows table to correctly handle
          // internal busy state in case of error
          return [];
        });
    },
  },
};
</script>
